var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"3":function(container,depth0,helpers,partials,data) {
    return "hidden ";
},"5":function(container,depth0,helpers,partials,data) {
    return " readonly ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"expiration-section compose-option-section\">\n  <div role=\"group\" aria-labelledby=\"expiration-label\">\n    <div class=\"form-check compose-option-group\" role=\"presentation\">\n      <input type=\"checkbox\" id=\"expiration-checkbox\"\n             class=\"form-check-input expiration\"\n             "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readonly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":13},"end":{"line":6,"column":46}}})) != null ? stack1 : "")
    + "\n             "
    + alias4(((helper = (helper = lookupProperty(helpers,"checked") || (depth0 != null ? lookupProperty(depth0,"checked") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checked","hash":{},"data":data,"loc":{"start":{"line":7,"column":13},"end":{"line":7,"column":24}}}) : helper)))
    + ">\n      <label class=\"form-check-label\" id=\"expiration-label\" for=\"expiration-checkbox\">Completion Deadline</label>\n    </div>\n    <div id=\"expiration-details\" class=\"form-group expiration-in-days\" role=\"presentation\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":91},"end":{"line":10,"column":128}}})) != null ? stack1 : "")
    + ">\n      <div class=\"expiration-in-days-div row  align-items-center g-2\" role=\"presentation\">\n        <div class=\"col-auto\">\n          <input id=\"expiration-in-days-val\" type=\"number\" class=\"form-control expiration-input\"\n                 value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":33}}}) : helper)))
    + "\"\n                 min=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"minDays") || (depth0 != null ? lookupProperty(depth0,"minDays") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minDays","hash":{},"data":data,"loc":{"start":{"line":15,"column":22},"end":{"line":15,"column":33}}}) : helper)))
    + "\"\n                 max=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxDays") || (depth0 != null ? lookupProperty(depth0,"maxDays") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxDays","hash":{},"data":data,"loc":{"start":{"line":16,"column":22},"end":{"line":16,"column":33}}}) : helper)))
    + "\"\n                 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readonly") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":17},"end":{"line":17,"column":50}}})) != null ? stack1 : "")
    + "\n                 aria-labelledby=\"expiration-in-days-label expiration-in-days-end-label\"\n                 aria-describedby=\"expiration-in-days-desc\"\n                 aria-controls=\"expiration-in-days-desc\"\n                 required />\n        </div>\n        <div class=\"col-auto\">\n          <label class=\"col-form-label\" id=\"expiration-in-days-label\" for=\"expiration-in-days-val\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"inputLabel") || (depth0 != null ? lookupProperty(depth0,"inputLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputLabel","hash":{},"data":data,"loc":{"start":{"line":24,"column":99},"end":{"line":24,"column":113}}}) : helper)))
    + "</label>\n        </div>\n      </div>\n      <div class=\"ancillary-text\" role=\"presentation\">\n        <span class=\"medium expiration-label-end\" id=\"expiration-in-days-end-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"inputLabelEnd") || (depth0 != null ? lookupProperty(depth0,"inputLabelEnd") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputLabelEnd","hash":{},"data":data,"loc":{"start":{"line":28,"column":84},"end":{"line":28,"column":101}}}) : helper)))
    + "</span>\n        <span id=\"expiration-in-days-desc\" aria-live=\"polite\" aria-relevant=\"additions text\" aria-atomic=\"true\">\n          <span class=\"medium expiration-after-text\" hidden></span>\n          <span class=\"medium expiration-error-text\" hidden></span>\n        </span>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " checked disabled ";
},"3":function(container,depth0,helpers,partials,data) {
    return "hidden ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"pdf-protect-section compose-option-section\">\n  <div role=\"group\" aria-labelledby=\"pdf-protect-label\">\n    <div class=\"form-check compose-option-group\" role=\"presentation\">\n      <input type=\"checkbox\" id=\"pdf-protect\"\n             class=\"form-check-input pdf-protect\"\n             "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":13},"end":{"line":6,"column":54}}})) != null ? stack1 : "")
    + ">\n      <label class=\"form-check-label\" id=\"pdf-protect-label\" for=\"pdf-protect\">Password Protect</label>\n    </div>\n    <div id=\"password-container\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":33},"end":{"line":9,"column":71}}})) != null ? stack1 : "")
    + ">\n      <p class=\"pass-strength-subtitle\">Password must contain 3 to 32 characters.</p>\n      <div class=\"pass-input\">\n        <input\n          type=\"password\"\n          aria-label=\"Password\"\n          autocomplete=\"off\"\n          placeholder=\"Password\"\n          id='protect-password'\n          class=\"password form-control has-error\"\n          required>\n        <input\n          type=\"password\"\n          aria-label=\"Confirm password\"\n          autocomplete=\"off\"\n          placeholder=\"Confirm password\"\n          id='protect-password-confirm'\n          class=\"confirm-pss form-control\"\n          required>\n        <div class=\"form-check control-label small\">\n          <input type=\"checkbox\" class=\"form-check-input password-checkbox\" id=\"show-password\">\n          <label class=\"form-check-label\" for=\"show-password\">Show password</label>\n        </div>\n        <div class=\"password-error\"></div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
import DOMUtils from "../util/DOMUtils";
import fileSelectTemplate from "../../hbsTemplates/FileSelect.hbs";
import { FileData, FileInfo, LibDocInfo } from "../models";
import Validator, { ValidationTracker } from "../Validator";

export class FileSelect {
  config: FileInfo;
  libDocList: LibDocInfo[];
  private _selectNode: HTMLSelectElement;

  constructor(config: FileInfo) {
    this.config = config;
    this.libDocList = config.workflowLibraryDocumentSelectorList || [];
  }

  addToDOM(parentNode: HTMLElement): void {
    const data = {
      inputId: "file_" + this.config.name,
      label: this.config.label,
      required: this.config.required,
      libDocList: this.libDocList,
    };

    if (!this.config.required) {
      data.libDocList.push({
        label: "-- None --",
        workflowLibDoc: null,
      });
    } else if (this.libDocList.length > 1) {
      data.libDocList.push({
        label: "-- Select a document --",
        workflowLibDoc: null,
      });
    }

    data.libDocList.sort((a, b) => {
      if (a.workflowLibDoc == null) {
        return -1;
      } else if (b.workflowLibDoc == null) {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });

    // Create the div
    const tempDiv: HTMLDivElement = document.createElement("div");
    tempDiv.innerHTML = fileSelectTemplate(data);
    const div = tempDiv.firstChild as HTMLDivElement;
    parentNode.appendChild(div);

    //create hooks
    this._selectNode = div.querySelector<HTMLSelectElement>("select");
  }

  setupValidation(validator: Validator): void {
    const validationTracker = validator.createTracker(this._selectNode, this.runValidation);

    this._selectNode.addEventListener("change", (event: Event) => {
      this.runValidation(validationTracker, event, false);
    });
  }

  runValidation = (
    validationTracker: ValidationTracker,
    _event: Event,
    _isRevalidate: boolean,
  ): void => {
    let error = false;
    let message = null;
    const docId = this._selectNode.value;

    if (this.config.required && docId == "") {
      error = true;
      message = `The file "${this.config.label}" is required.`;
    }

    if (error) {
      this._selectNode.classList.add("is-invalid");
    } else {
      DOMUtils.removeClass(this._selectNode, "is-invalid");
    }

    validationTracker.update(error, message);
  };

  getValue(): FileData | null {
    const docId = this._selectNode.value;
    if (docId) {
      return {
        name: this.config.name,
        workflowLibraryDocumentId: docId,
      };
    }
    return null;
  }
}

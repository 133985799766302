var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"3":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"5":function(container,depth0,helpers,partials,data) {
    return "hidden ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":16,"column":57},"end":{"line":16,"column":66}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":67},"end":{"line":16,"column":100}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"descr") || (depth0 != null ? lookupProperty(depth0,"descr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"descr","hash":{},"data":data,"loc":{"start":{"line":16,"column":101},"end":{"line":16,"column":110}}}) : helper)))
    + "</option>";
},"8":function(container,depth0,helpers,partials,data) {
    return " selected ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"reminder-section compose-option-section\">\n  <div role=\"group\" aria-labelledby=\"reminder-option-label\">\n    <div class=\"form-check compose-option-group\">\n      <input type=\"checkbox\" id=\"reminder-option\"\n             class=\"form-check-input reminder-option\"\n             "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":13},"end":{"line":6,"column":46}}})) != null ? stack1 : "")
    + "\n             "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":13},"end":{"line":7,"column":44}}})) != null ? stack1 : "")
    + ">\n      <label class=\"form-check-label\" id=\"reminder-option-label\" for=\"reminder-option\">Set Reminder</label>\n    </div>\n    <div id=\"reminder-details\" class=\"form-group reminder-frequency\" role=\"group\"\n         aria-labelledby=\"reminder-option-label\"\n         "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":9},"end":{"line":12,"column":46}}})) != null ? stack1 : "")
    + ">\n      <select id=\"reminder-frequency\" class=\"form-control reminder-frequency-select\"\n              aria-label=\"Reminder Frequency\"\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":14},"end":{"line":15,"column":47}}})) != null ? stack1 : "")
    + ">\n        "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"reminderFrequencyOptions") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":128}}})) != null ? stack1 : "")
    + "\n      </select>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
import DOMUtils from "../util/DOMUtils";
import passwordOptionTemplate from "../../hbsTemplates/PasswordOption.hbs";
import passwordOptionErrorTemplate from "../../hbsTemplates/PasswordOptionError.hbs";
import { PasswordFieldInfo, SecurityOptionData } from "../models";
import Validator, { ValidationTracker } from "../Validator";

export class PasswordOption {
  config: PasswordFieldInfo;
  private _protectCheckbox: HTMLInputElement;
  private _passContainer: HTMLDivElement;
  private _showPassCheckbox: HTMLInputElement;
  private _passwordInput: HTMLInputElement;
  private _passwordConfirmInput: HTMLInputElement;
  private _passwordErrorDiv: HTMLDivElement;
  private _passwordConfirmEdited: boolean;

  constructor(config: PasswordFieldInfo) {
    this.config = config;

    this._passwordConfirmEdited = false;
  }

  addToDOM(parentNode: HTMLElement): void {
    const data = {
      required: this.config.required,
    };

    // Create the div
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = passwordOptionTemplate(data);
    const div = tempDiv.firstChild as HTMLDivElement;
    parentNode.appendChild(div);

    //create hooks
    this._protectCheckbox = div.querySelector<HTMLInputElement>("input#pdf-protect");
    this._passContainer = div.querySelector<HTMLDivElement>("#password-container");
    this._showPassCheckbox = div.querySelector<HTMLInputElement>("#show-password");
    this._passwordInput = div.querySelector<HTMLInputElement>("#protect-password");
    this._passwordConfirmInput = div.querySelector<HTMLInputElement>("#protect-password-confirm");
    this._passwordErrorDiv = div.querySelector<HTMLDivElement>(".password-error");

    // Add event handlers
    this._protectCheckbox.onclick = () => {
      // show sub pass div
      if (this._protectCheckbox.checked === true) {
        this._passContainer.hidden = false;
        this._passwordInput.focus();
      }
      // hide sub pass div
      else {
        this._passContainer.hidden = true;
      }
    };

    this._showPassCheckbox.onclick = () => {
      if (this._showPassCheckbox.checked === false) {
        this._passwordInput.type = "password";
        this._passwordConfirmInput.type = "password";
      } else {
        this._passwordInput.type = "text";
        this._passwordConfirmInput.type = "text";
      }
    };
  }

  setupValidation(validator: Validator): void {
    const validationTracker = validator.createTracker(this._passwordInput, this.runValidation);

    this._protectCheckbox.addEventListener("click", (event: Event) => {
      //only run when unchecking
      if (this._protectCheckbox.checked !== true) {
        this.runValidation(validationTracker, event, false);
      }
    });

    this._passwordInput.addEventListener("change", (event: Event) => {
      this.runValidation(validationTracker, event, false);
    });

    this._passwordConfirmInput.addEventListener("change", (event: Event) => {
      this.runValidation(validationTracker, event, false);
    });
  }

  runValidation = (
    validationTracker: ValidationTracker,
    event: Event,
    isRevalidate: boolean,
  ): void => {
    let error = false;
    let message = null;
    const passwordInput = this._passwordInput;
    const password = passwordInput.value;
    const passwordConfirmInput = this._passwordConfirmInput;
    const passwordConfirm = passwordConfirmInput.value;
    const messages = [];

    if (isRevalidate || (event && (event.target as HTMLElement).id == "protect-password-confirm")) {
      this._passwordConfirmEdited = true;
    }

    if (this._protectCheckbox.checked === true) {
      if (this._passwordConfirmEdited && password != passwordConfirm) {
        error = true;
        messages.push(`Passwords do not match.`);
        passwordConfirmInput.classList.add("is-invalid");
      }

      if (password.length < 3) {
        error = true;
        messages.push(`Password needs to be at least 3 characters long.`);
      }

      if (password.length > 32) {
        error = true;
        messages.push(`Password exceeds 32 characters.`);
      }
    }

    if (error) {
      message = `You need to set a password to protect the downloaded PDF.`;

      // show the error message
      if (messages.length > 1) {
        this._passwordErrorDiv.innerHTML = passwordOptionErrorTemplate({ messages: messages });
      } else {
        this._passwordErrorDiv.innerHTML = passwordOptionErrorTemplate({ message: messages[0] });
      }

      //format inputs with error
      passwordInput.classList.add("is-invalid");
    } else {
      this._passwordErrorDiv.innerText = "";
      DOMUtils.removeClass(passwordInput, "is-invalid");
      DOMUtils.removeClass(passwordConfirmInput, "is-invalid");
    }

    validationTracker.update(error, message);
  };

  getValue(): SecurityOptionData {
    const values: SecurityOptionData = {
      protectOpen: this._protectCheckbox.checked,
    };

    if (this._protectCheckbox.checked === true) {
      values.openPassword = this._passwordInput.value;
    }

    return values;
  }
}

import Axios, { AxiosResponse, AxiosHeaders, AxiosInstance } from "axios";
import { APIException, InternalServerError } from "./Exceptions";
import {
  AgreementCreationResponse,
  ApiErrorResponseData,
  PowerFormAgreementCreate,
  PowerFormInfo,
  TransientDocumentResponse,
} from "../models";

export class ApiClient {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = Axios.create({
      baseURL: baseURL,
    });
  }

  async getWorflowConfig(workflowId: string): Promise<PowerFormInfo> {
    try {
      const workflowReq = await this.api.get<PowerFormInfo>(`/${workflowId}`);
      return workflowReq.data;
    } catch (e) {
      if (Axios.isAxiosError<ApiErrorResponseData>(e) && e.response) {
        const requestId = getRequestId(e.response);
        console.error(
          `API Error: ${e.response.status} ${requestId ? "RequestId:'" + requestId + "'" : ""}`,
          e.response,
        );
        if (
          e.response.data &&
          e.response.data.detail &&
          (e.response.data.detail.code || e.response.data.detail.message)
        ) {
          throw new APIException(e.response.data.detail, requestId);
        } else if (e.response.status == 500) {
          throw new InternalServerError(e.response.data, requestId);
        }
      }

      console.error(e);
      throw e;
    }
  }

  async postTransientDocument(file: File): Promise<TransientDocumentResponse> {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const apiResponse = await this.api.post<TransientDocumentResponse>(
        `/transientDocuments`,
        formData,
      );
      return apiResponse.data;
    } catch (e) {
      if (Axios.isAxiosError<ApiErrorResponseData>(e) && e.response) {
        const requestId = getRequestId(e.response);
        console.error(
          `API Error: ${e.response.status} ${requestId ? "RequestId:'" + requestId + "'" : ""}`,
          e.response,
        );
        if (
          e.response.data &&
          e.response.data.detail &&
          (e.response.data.detail.code || e.response.data.detail.message)
        ) {
          throw new APIException(e.response.data.detail, requestId);
        } else if (e.response.status == 413) {
          throw new APIException({ code: "LARGE_FILE_SIZE", ...e.response.data }, requestId);
        } else if (e.response.status == 500) {
          throw new InternalServerError(e.response.data, requestId);
        }
      }

      console.error(e);
      throw e;
    }
  }

  async postWorkflowAgreement(
    workflowId: string,
    agreementData: PowerFormAgreementCreate,
  ): Promise<AgreementCreationResponse> {
    try {
      const apiResponse = await this.api.post<AgreementCreationResponse>(
        `/${workflowId}/agreements`,
        agreementData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return apiResponse.data;
    } catch (e) {
      if (Axios.isAxiosError<ApiErrorResponseData>(e) && e.response) {
        const requestId = getRequestId(e.response);
        console.error(
          `API Error: ${e.response.status} ${requestId ? "RequestId:'" + requestId + "'" : ""}`,
          e.response,
        );
        if (
          e.response.data &&
          e.response.data.detail &&
          (e.response.data.detail.code || e.response.data.detail.message)
        ) {
          throw new APIException(e.response.data.detail, requestId);
        } else if (e.response.status == 500) {
          throw new InternalServerError(e.response.data, requestId);
        }
      }

      console.error(e);
      throw e;
    }
  }
}

/**
 * Gets the AWS reponse id from an API response
 * @param response The Axios response
 * @returns requestId
 */
function getRequestId(response: AxiosResponse): string | undefined {
  // Get the AWS request IS
  if (response.headers instanceof AxiosHeaders) {
    const requestId = response.headers.get("apigw-requestid");
    if (typeof requestId === "string") {
      return requestId;
    }
  }
  return undefined;
}

import BootstrapModal from "bootstrap/js/dist/modal";
import loaderTemplate from "../../hbsTemplates/Loader.hbs";
import errorTemplate from "../../hbsTemplates/ErrorModal.hbs";
import { errorTemplateConfig } from "../util/Exceptions";

export default class Modal {
  private _appDiv: HTMLElement;
  private _loaderModal: BootstrapModal;
  private _loaderTitleDiv: HTMLDivElement;
  private _errorModal: BootstrapModal;

  constructor(appDiv: HTMLElement) {
    this._appDiv = appDiv;

    this._createLoader();
  }

  private _createLoader(): void {
    // Create the Modal
    const tempDiv: HTMLDivElement = document.createElement("div");
    tempDiv.innerHTML = loaderTemplate({});
    const div = tempDiv.firstChild as HTMLDivElement;
    this._appDiv.appendChild(div);
    this._loaderModal = new BootstrapModal(div, { backdrop: "static", keyboard: false });

    // get the title so it can be updated later
    this._loaderTitleDiv = div.querySelector<HTMLDivElement>(".modal-title");
  }

  showLoader(body: string): void {
    this._loaderTitleDiv.innerHTML = body;
    this._loaderModal.show();
  }

  showError(config: errorTemplateConfig): void {
    // Create the Modal
    const tempDiv: HTMLDivElement = document.createElement("div");
    tempDiv.innerHTML = errorTemplate(config);
    const div = tempDiv.firstChild as HTMLDivElement;
    this._appDiv.appendChild(div);
    this._errorModal = new BootstrapModal(div, { backdrop: "static", keyboard: false });

    // Show the modal
    this._loaderModal.hide();
    this._errorModal.show();
  }

  remove(): void {
    this._loaderModal.hide();
    if (this._errorModal) {
      this._errorModal.hide();
    }
  }
}

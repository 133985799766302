import { AgreementCreationResponse, PowerFormAgreementCreate, PowerFormInfo } from "./models";
import { ApiClient } from "./util/APIClient";
import {
  APIException,
  DocUploadError,
  HandledException,
  InternalServerError,
} from "./util/Exceptions";

export class WorkflowService {
  readonly workflowId: string;
  private config: ClientConfig;
  private apiClient: ApiClient;

  constructor(workflowId: string, config: ClientConfig, apiClient: ApiClient) {
    this.workflowId = workflowId;
    this.config = config;
    this.apiClient = apiClient;
  }

  async getWorflowConfig(): Promise<PowerFormInfo> {
    try {
      return await this.apiClient.getWorflowConfig(this.workflowId);
    } catch (e) {
      if (e instanceof APIException) {
        console.log(e.name, e.apiData);

        if (e.apiData.code) {
          const code = e.apiData.code;
          if (code == "DISABLED_WORKFLOW" || code == "PERMISSION_DENIED") {
            throw new HandledException(
              "Error",
              e.requestId,
              "The requested PowerForm is not currently available.",
            );
          } else if (code == "NOT_FOUND" || code == "INVALID_WORKFLOW_ID") {
            throw new HandledException(
              "Error",
              e.requestId,
              "The requested PowerForm could not be found.",
            );
          } else if (code == "POWERFORM_VALIDATION") {
            throw new HandledException("Error", e.requestId, e.apiData.message);
          }
        }
        if (e.apiData.message) {
          throw new HandledException("Error", e.requestId, `Error response: ${e.apiData.message}`);
        }
      } else if (e instanceof InternalServerError) {
        console.log(e.name, e.message);

        throw new HandledException(
          "Error",
          e.requestId,
          "Sorry, a server error has occured. Please try again later or open a support ticket.",
        );
      }

      throw e;
    }
  }

  async submitAgreement(
    agreementData: PowerFormAgreementCreate,
  ): Promise<AgreementCreationResponse> {
    try {
      const apiResponse = await this.apiClient.postWorkflowAgreement(
        this.workflowId,
        agreementData,
      );
      console.log("apiResponse", apiResponse);
      return apiResponse;
    } catch (e) {
      if (e instanceof APIException) {
        console.log(e.name, e.apiData);

        if (e.apiData.code) {
          const code = e.apiData.code;
          if (code == "DISABLED_WORKFLOW" || code == "PERMISSION_DENIED") {
            throw new HandledException(
              "Error",
              e.requestId,
              "The requested PowerForm is not currently available.",
            );
          } else if (code == "NOT_FOUND" || code == "INVALID_WORKFLOW_ID") {
            throw new HandledException(
              "Error",
              e.requestId,
              "The requested PowerForm could not be found.",
            );
          } else if (code == "POWERFORM_VALIDATION" || code == "INVALID_EMAIL") {
            throw new HandledException("Error", e.requestId, e.apiData.message, true);
          }
        }
        if (e.apiData.message) {
          throw new HandledException("Error", e.requestId, `Error response: ${e.apiData.message}`);
        }
      } else if (e instanceof InternalServerError) {
        console.log(e.name, e.message);

        throw new HandledException(
          "Error",
          e.requestId,
          "Sorry, a server error has occured. Please try again later or open a support ticket.",
        );
      }

      throw e;
    }
  }

  async uploadTransientDocument(file: File): Promise<string> {
    // Check file size
    const maxFileSize = this.config.maxUploadSizeMb;
    if (maxFileSize) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > maxFileSize) {
        throw new DocUploadError(`File must be less than ${maxFileSize} MB.`);
      }
    }

    // Upload to Adobe Sign
    try {
      const transientDocument = await this.apiClient.postTransientDocument(file);
      return transientDocument.transientDocumentId;
    } catch (e) {
      if (e instanceof APIException) {
        if (e.apiData.code) {
          const code = e.apiData.code;
          if (code == "UNSUPPORTED_MEDIA_TYPE") {
            throw new DocUploadError("Unsupported file type.");
          } else if (code == "LARGE_FILE_SIZE") {
            throw new DocUploadError("File size too large.");
          } else if (code == "NO_FILE_CONTENT") {
            throw new DocUploadError("The file is empty.");
          } else if (e.apiData.message) {
            throw new DocUploadError(e.apiData.message);
          }
        }
      }

      console.error("Unhandled error", e);
      throw new DocUploadError("Error uploading file.");
    }
  }
}

import formErrorsTemplate from "../../hbsTemplates/FormErrors.hbs";
import Validator, { ValidationObserver, ValidationErrors } from "../Validator";
import Collapse from "bootstrap/js/dist/collapse";

export class FormErrors {
  private _sectionNode: HTMLElement;
  private _validator: Validator;
  private _observer: ValidationObserver;
  private _submitButton: HTMLButtonElement | null;
  private _showErrorDiv: boolean;
  private _hasErrors: boolean;
  private _currentErrors: ValidationErrors;
  private _bsCollapse: Collapse;

  constructor(sectionNode: HTMLElement, validator: Validator) {
    this._sectionNode = sectionNode;
    this._validator = validator;
    this._submitButton = null;
    this._showErrorDiv = false;

    this._observer = validator.createObserver("FormErrors", this.handleErrors);

    this._hasErrors = false;
    this._currentErrors = {};

    this._bsCollapse = new Collapse(this._sectionNode, {});
    this._bsCollapse.hide();
  }

  connectSubmitButton(submitButton: HTMLButtonElement): void {
    this._submitButton = submitButton;
  }

  handleErrors = (errors?: ValidationErrors): void => {
    if (errors) {
      this._currentErrors = errors;
    }

    const keys = entries(this._currentErrors);
    this._hasErrors = keys.length > 0;

    if (this._showErrorDiv) {
      const data: ErrorsData = {};

      if (this._submitButton !== null) {
        this._submitButton.disabled = this._hasErrors;
      }

      if (this._hasErrors) {
        data.errors = {};
        keys.sort().forEach((key: number) => {
          data.errors[key] = this._currentErrors[key];
        });
        this._sectionNode.innerHTML = formErrorsTemplate(data);
        this._bsCollapse.show();
      } else {
        this._sectionNode.innerHTML = formErrorsTemplate(data);
        this._bsCollapse.hide();
      }
    }
  };

  showErrorDiv(): void {
    this._showErrorDiv = true;
    this.handleErrors();
  }

  hasErrors(): boolean {
    return this._hasErrors;
  }

  scrollToError(): void {
    this._sectionNode.scrollIntoView({
      behavior: "smooth",
    });
  }
}

interface ErrorsData {
  errors?: ValidationErrors;
}

function entries<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

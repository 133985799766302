import DOMUtils from "../util/DOMUtils";
import fileUploadTemplate from "../../hbsTemplates/FileUpload.hbs";
import { DocUploadError } from "../util/Exceptions";
import { FileData, FileInfo } from "../models";
import Validator, { ValidationTracker } from "../Validator";
import { WorkflowService } from "../WorkflowService";

export class FileUpload {
  readonly config: FileInfo;
  readonly wfService: WorkflowService;
  private _transientDocumentId: string | null;
  private _uploadError: string | null;
  private _uploadNode: HTMLInputElement;
  private _errorDescrNode: HTMLDivElement;

  constructor(config: FileInfo, wfService: WorkflowService) {
    this.config = config;
    this.wfService = wfService;

    this._transientDocumentId = null;
    this._uploadError = null;
  }

  addToDOM(parentNode: HTMLElement): void {
    const data = {
      inputId: "file_" + this.config.name,
      label: this.config.label,
      required: this.config.required,
    };

    // Create the div
    const tempDiv: HTMLDivElement = document.createElement("div");
    tempDiv.innerHTML = fileUploadTemplate(data);
    const div: HTMLDivElement = tempDiv.firstChild as HTMLDivElement;
    parentNode.appendChild(div);

    //create hooks
    this._uploadNode = div.querySelector<HTMLInputElement>("input");
    this._errorDescrNode = div.querySelector<HTMLDivElement>(".invalid-feedback");
  }

  setupValidation(validator: Validator) {
    const validationTracker = validator.createTracker(this._uploadNode, this.runValidation);

    const asyncListener = async (event: Event): Promise<void> => {
      this._uploadError = await this.handleFileUpload();
      this.runValidation(validationTracker, event, false);
    };

    this._uploadNode.addEventListener("change", (event: Event) => {
      void asyncListener(event);
    });
  }

  runValidation = (
    validationTracker: ValidationTracker,
    _event: Event,
    _isRevalidate: boolean,
  ): void => {
    let error = false;
    let message = null;
    let helperText = null;
    const docId = this._transientDocumentId;

    if (this._uploadError) {
      error = true;
      message = `File "${this.config.label}": ${this._uploadError}`;
      helperText = this._uploadError;
    } else if (this.config.required && !docId) {
      error = true;
      message = `The file "${this.config.label}" is required.`;
      helperText = "Please select a file to upload.";
    }

    if (error) {
      this._uploadNode.classList.add("is-invalid");
      this._errorDescrNode.innerText = helperText;
    } else {
      DOMUtils.removeClass(this._uploadNode, "is-invalid");
    }

    validationTracker.update(error, message);
  };

  getValue(): FileData | null {
    const docId = this._transientDocumentId;
    if (docId) {
      return {
        name: this.config.name,
        transientDocumentId: docId,
      };
    }
    return null;
  }

  /**
   * Uploads a file to Adobe Sign
   * @returns {string | null} An error message or null
   */
  handleFileUpload = async (): Promise<string | null> => {
    const file = this._uploadNode.files[0];

    // If no file, reset the field
    if (!file) {
      this._transientDocumentId = null;
      return null;
    }

    this._transientDocumentId = null;

    try {
      this._transientDocumentId = await this.wfService.uploadTransientDocument(file);
      return null;
    } catch (e) {
      if (e instanceof DocUploadError) {
        return e.message;
      }
    }
    return "Error uploading file.";
  };
}

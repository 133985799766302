import Validator, { ValidationTracker } from "../Validator";
import { CCsListInfo, CarbonCopyData } from "../models";
import { CarbonCopyLine } from "./CarbonCopyLine";

export class CarbonCopyGroup {
  id: number;
  config: CCsListInfo;
  carbonCopyLines: CarbonCopyLine[];

  constructor(id: number, config: CCsListInfo) {
    this.id = id;
    this.config = config;

    this.carbonCopyLines = [];
  }

  addToDOM(parentNode: HTMLElement): void {
    const fieldsetNode = document.createElement("fieldset");
    parentNode.appendChild(fieldsetNode);

    const headerNode = document.createElement("legend");
    headerNode.innerHTML = this.config.label;
    fieldsetNode.appendChild(headerNode);

    const readOnly = !this.config.editable;
    const defaultValuesCount = this.config.defaultValues.length;
    const maxCount = this.config.editable ? this.config.maxListCount : defaultValuesCount;

    for (let i = 1; i <= maxCount; i++) {
      const required = i <= this.config.minListCount;
      const ccLine = new CarbonCopyLine(i, this.config.defaultValues[i - 1], required, readOnly);
      ccLine.addToDOM(fieldsetNode);
      this.carbonCopyLines.push(ccLine);
    }
  }

  setupValidation(validator: Validator): void {
    this.carbonCopyLines.forEach((carbonCopyLine) => {
      carbonCopyLine.setupValidation(validator);
    });
  }

  runValidation = (
    validationTracker: ValidationTracker,
    event: Event,
    isRevalidate: boolean,
  ): void => {
    this.carbonCopyLines.forEach((carbonCopyLine) => {
      carbonCopyLine.runValidation(validationTracker, event, isRevalidate);
    });
  };

  getValue(): CarbonCopyData | null {
    if (this.config.editable) {
      return {
        name: this.config.name,
        emails: this.carbonCopyLines.reduce((results: string[], carbonCopyLine: CarbonCopyLine) => {
          const val: string | null = carbonCopyLine.getValue();
          if (val !== null && val != "") {
            results.push(val);
          }
          return results;
        }, []),
      };
    }
    return null;
  }
}

export enum ReminderFrequency {
  DAILY_UNTIL_SIGNED = "DAILY_UNTIL_SIGNED",
  WEEKDAILY_UNTIL_SIGNED = "WEEKDAILY_UNTIL_SIGNED",
  EVERY_OTHER_DAY_UNTIL_SIGNED = "EVERY_OTHER_DAY_UNTIL_SIGNED",
  EVERY_THIRD_DAY_UNTIL_SIGNED = "EVERY_THIRD_DAY_UNTIL_SIGNED",
  EVERY_FIFTH_DAY_UNTIL_SIGNED = "EVERY_FIFTH_DAY_UNTIL_SIGNED",
  WEEKLY_UNTIL_SIGNED = "WEEKLY_UNTIL_SIGNED",
  ONCE = "ONCE",
}

export interface PowerFormInfo {
  displayName: string;
  instructions: string;
  agreementName: PowerFormParamInfo;
  message: PowerFormParamInfo;
  recipients?: RecipientInfo[];
  carbonCopies?: CCsListInfo[];
  files?: FileInfo[];
  mergeFields?: MergeFieldInfo[];
  reminder?: PowerFormParamInfo;
  expiration?: ExpirationInfo;
  password: PasswordFieldInfo;
}

/**
 * Configuration of a PowerForm parameter
 */
export interface PowerFormParamInfo {
  defaultValue: string;
  editable: boolean;
  visible?: boolean;
}

/**
 * Configuration of a recipient in a PowerForm
 */
export interface RecipientInfo {
  name: string;
  label: string;
  defaultValue: string;
  editable: boolean;
  minListCount: number;
  maxListCount: number;
}

/**
 * Configuration of a CC list in a PowerForm
 */
export interface CCsListInfo {
  name: string;
  label: string;
  defaultValues: string[];
  editable: boolean;
  minListCount: number;
  maxListCount: number;
}

/**
 * Configuration of a file on the PowerForm
 */
export interface FileInfo {
  name: string;
  label: string;
  required: boolean;
  workflowLibraryDocumentSelectorList?: LibDocInfo[];
}

/**
 * Configuration of a library document attached to the PowerForm
 */
export interface LibDocInfo {
  label: string;
  workflowLibDoc: string;
}

/**
 * Custom input field to use in the agreement creation process
 */
export interface MergeFieldInfo {
  fieldName: string;
  displayName: string;
  defaultValue: string;
  editable: boolean;
  required: boolean;
}

/**
 * Configuration of the agreement expiration date for a PowerForm
 */
export interface ExpirationInfo {
  defaultValue: string;
  editable: boolean;
  maxDays: number;
  required: boolean;
  visible?: boolean | null;
}

/**
 * Configuration of the agreement open password for a PowerForm
 */
export interface PasswordFieldInfo {
  required: boolean;
  visible: boolean;
}

/**
 * Response to an upload of a transient document
 */
export interface TransientDocumentResponse {
  transientDocumentId: string;
}

/**
 * Information provided by the client to create an Agreement from a PowerForm
 */
export interface PowerFormAgreementCreate {
  agreementName?: string | null;
  message?: string | null;
  recipients?: ParticipantSetData[];
  carbonCopy?: CarbonCopyData[];
  files?: FileData[];
  mergeFields?: MergeFieldData[];
  reminder?: ReminderFrequency | null;
  expiration?: number | null;
  password?: SecurityOptionData | null;
}

/**
 * Participant set data from the client
 */
export interface ParticipantSetData {
  name: string;
  recipients: ParticipantSetMemberData[];
}

/**
 * A individual participant in a set
 */
export interface ParticipantSetMemberData {
  email: string;
}

/**
 * Carbon Copy data from the client
 */
export interface CarbonCopyData {
  name: string;
  emails?: string[];
}

/**
 * A library document selected or previously uploaded by the client
 */
export interface FileData {
  name: string;
  workflowLibraryDocumentId?: string;
  transientDocumentId?: string;
}

/**
 * Custom input field data from the client
 */
export interface MergeFieldData {
  fieldName: string;
  defaultValue: string;
}

/**
 * Security settings set by the client
 */
export interface SecurityOptionData {
  protectOpen: boolean;
  openPassword?: string;
}

/**
 * Response when creating an agreement
 */
export interface AgreementCreationResponse {
  agreementId: string;
}

export interface ApiErrorResponseData {
  detail: ApiErrorDetails;
}

export interface ApiErrorDetails {
  code?: string;
  message?: string;
}

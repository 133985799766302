import Utils from "../util/Utils";
import DOMUtils from "../util/DOMUtils";
import carbonCopyLineTemplate from "../../hbsTemplates/CarbonCopyLine.hbs";
import Validator, { ValidationTracker } from "../Validator";

export class CarbonCopyLine {
  id: number;
  defaultValue: string;
  required: boolean;
  readonly: boolean;
  private _inputNode: HTMLInputElement;

  constructor(id: number, defaultValue: string, required: boolean, readonly: boolean) {
    this.id = id;
    this.defaultValue = defaultValue;
    this.required = required;
    this.readonly = readonly;
  }

  addToDOM(parentNode: HTMLElement): void {
    const data = {
      inputId: "cc_" + this.id,
      label: "CC " + this.id,
      defaultValue: this.defaultValue,
      placeholder: "Enter Cc's Email",
      required: this.required,
      readonly: this.readonly,
    };

    // Create the div
    const tempDiv: HTMLDivElement = document.createElement("div");
    tempDiv.innerHTML = carbonCopyLineTemplate(data);
    const div = tempDiv.firstChild as HTMLDivElement;
    parentNode.appendChild(div);

    //create hooks
    this._inputNode = div.querySelector<HTMLInputElement>("input");
  }

  setupValidation(validator: Validator): void {
    const validationTracker = validator.createTracker(this._inputNode, this.runValidation);

    this._inputNode.addEventListener("change", (event) => {
      this.runValidation(validationTracker, event, false);
    });
  }

  runValidation = (
    validationTracker: ValidationTracker,
    _event: Event,
    _isRevalidate: boolean,
  ): void => {
    let error = false;
    let message: string | null = null;
    const email = this._inputNode.value;

    if (this.required && email == "") {
      error = true;
      message = `CC recipient ${this.id} is required.`;
    } else if (email != "" && !Utils.isValidEmail(email)) {
      error = true;
      message = `The email "${email}" for CC recipient ${this.id} is not a valid email address.`;
    }

    if (error) {
      this._inputNode.classList.add("is-invalid");
    } else {
      DOMUtils.removeClass(this._inputNode, "is-invalid");
    }

    validationTracker.update(error, message);
  };

  getValue(): string | null {
    if (!this.readonly) {
      return this._inputNode.value;
    }
    return null;
  }
}

import DOMUtils from "../util/DOMUtils";
import * as DateUtils from "../util/Date";
import expirationDateTimeBasedTemplate from "../../hbsTemplates/ExpirationDateTimeBased.hbs";
import { ExpirationInfo } from "../models";
import Validator, { ValidationTracker } from "../Validator";

export class ExpirationDateTimeBased {
  config: ExpirationInfo;
  today: Date;
  dateSettings: DateSettings;
  private _expireCheckbox: HTMLInputElement;
  private _detailsDiv: HTMLDivElement;
  private _expirationInput: HTMLInputElement;

  constructor(config: ExpirationInfo) {
    this.config = config;

    this.today = new Date();
    this.dateSettings = this.getDateSettings();
  }

  addToDOM(parentNode: HTMLElement): void {
    const data = {
      checked: this.config.defaultValue ? "checked" : "",
      readonly: !this.config.editable,
      minDate: this.dateSettings.minDateStr,
      maxDate: this.dateSettings.maxDateStr,
      value: this.dateSettings.defaultDateStr,
    };

    // Create the div
    const tempDiv: HTMLDivElement = document.createElement("div");
    tempDiv.innerHTML = expirationDateTimeBasedTemplate(data);
    const div = tempDiv.firstChild as HTMLDivElement;
    parentNode.appendChild(div);

    //create hooks
    this._expireCheckbox = div.querySelector<HTMLInputElement>("#expiration-checkbox");
    this._detailsDiv = div.querySelector<HTMLDivElement>("#expiration-details");
    this._expirationInput = div.querySelector<HTMLInputElement>("#expiration-time");

    // Add event handlers
    this._expireCheckbox.onclick = () => {
      if (this._expireCheckbox.checked === true) {
        this._detailsDiv.hidden = false;
        this._expirationInput.focus();
      } else {
        this._detailsDiv.hidden = true;
      }
    };
  }

  setupValidation(validator: Validator): void {
    const validationTracker = validator.createTracker(this._expirationInput, this.runValidation);

    this._expireCheckbox.addEventListener("click", (event) => {
      //only run when unchecking
      if (this._expireCheckbox.checked !== true) {
        this.runValidation(validationTracker, event, false);
      }
    });

    this._expirationInput.addEventListener("change", (event) => {
      this.runValidation(validationTracker, event, false);
    });
  }

  runValidation = (
    validationTracker: ValidationTracker,
    _event: Event,
    _isRevalidate: boolean,
  ): void => {
    let error = false;
    let message = null;
    const expirationDate = this._expirationInput.value;

    if (this._expireCheckbox.checked === true) {
      if (this.config.editable && expirationDate == "") {
        error = true;
        message = `Please provide a valid value for agreement completion deadline.`;
      } else {
        const minDate = this.dateSettings.minDateStr;
        const maxDate = this.dateSettings.maxDateStr;

        if (expirationDate < minDate) {
          error = true;
          message = `Completion deadline must be ${DateUtils.format(
            this.dateSettings.minDate,
            "MM/DD/YYYY",
          )} or later.`;
        } else if (expirationDate > maxDate) {
          error = true;
          message = `Completion deadline must be ${DateUtils.format(
            this.dateSettings.maxDate,
            "MM/DD/YYYY",
          )} or earlier.`;
        }
      }
    }

    if (error) {
      this._expirationInput.classList.add("is-invalid");
    } else {
      DOMUtils.removeClass(this._expirationInput, "is-invalid");
    }

    validationTracker.update(error, message);
  };

  getValue(): number | null {
    if (this._expireCheckbox.checked === true) {
      const selected_date = new Date(this._expirationInput.value);

      const diffTime = Math.abs(selected_date.getTime() - this.today.getTime());
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    return null;
  }

  getDateSettings(): DateSettings {
    // Set max days and get string outputs
    const minDate = DateUtils.addDays(this.today, 1);
    const minDateStr = DateUtils.format(minDate, "YYYY-MM-DD");
    const maxDate = DateUtils.addDays(this.today, this.config.maxDays);
    const maxDateStr = DateUtils.format(maxDate, "YYYY-MM-DD");

    // if default date
    let defaultDateStr: string;
    if (this.config.defaultValue) {
      const defaultDate = DateUtils.addDays(this.today, Number(this.config.defaultValue));
      defaultDateStr = DateUtils.format(defaultDate, "YYYY-MM-DD");
    } else {
      defaultDateStr = DateUtils.format(this.today, "YYYY-MM-DD");
    }

    const dateSettings: DateSettings = {
      minDate: minDate,
      minDateStr: minDateStr,
      maxDate: maxDate,
      maxDateStr: maxDateStr,
      defaultDateStr: defaultDateStr,
    };

    return dateSettings;
  }
}

interface DateSettings {
  minDate: Date;
  minDateStr: string;
  maxDate: Date;
  maxDateStr: string;
  defaultDateStr: string;
}

import { App } from "./App";
import ParsePath from "./util/ParsePath";
import "../scss/style.scss";

declare global {
  interface Window {
    ClientConfig: ClientConfig;
  }
}

const parsePath = new ParsePath({
  sensitive: false,
  strict: false,
  end: true,
});

const app = new App(document.getElementById("app"), window.ClientConfig);

const pathTemplate = `${window.ClientConfig.baseURL}/${window.ClientConfig.workflowPath}/:id`;
const params = parsePath.test(pathTemplate.replace(/[/]+/g, "/"), window.location.pathname);
if (params !== false) {
  const workflowId = params.id;
  void app.loadWorkflow(workflowId);
} else {
  app.showError({ errorBody: "The page you have requested is invalid." });
}

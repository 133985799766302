import Utils from "../util/Utils";
import DOMUtils from "../util/DOMUtils";
import { ParticipantSetData, RecipientInfo } from "../models";
import Validator, { ValidationTracker } from "../Validator";

export class RecipientGroup {
  id: number;
  config: RecipientInfo;
  required: boolean;
  private _divNode: HTMLDivElement;
  private _inputNode: HTMLInputElement;

  constructor(id: number, config: RecipientInfo) {
    this.id = id;
    this.config = config;
    // this.number_of_members = 0;
    // this.divNode = null;
    // this._inputNode = null;

    this.required = !(this.config.minListCount == 0);
  }

  addToDOM(parentNode: HTMLElement): void {
    const inputId = "recipient_" + this.id;

    // Create the div
    const divNode: HTMLDivElement = document.createElement("div");
    divNode.id = "recipient_group_" + this.id;
    divNode.className = "form-group";
    parentNode.appendChild(divNode);
    this._divNode = divNode;

    // Create the label
    const labelNode: HTMLLabelElement = document.createElement("label");
    labelNode.innerHTML = this.config.label;
    labelNode.htmlFor = inputId;
    divNode.appendChild(labelNode);

    // Create the input
    const inputNode: HTMLInputElement = document.createElement("input");
    inputNode.type = "email";
    inputNode.id = inputId;
    inputNode.name = inputId;
    inputNode.className = "form-control";
    inputNode.placeholder = "Enter Recipient's Email";
    divNode.appendChild(inputNode);

    const feedbackNode: HTMLDivElement = document.createElement("div");
    feedbackNode.className = "invalid-feedback";
    feedbackNode.innerText = "Please provide a valid email address";
    divNode.appendChild(feedbackNode);

    if (this.required) {
      inputNode.required = true;
      labelNode.classList.add("required");
    }

    // If data is not blank, fill it in with predefine information
    if (this.config.defaultValue !== "") {
      inputNode.value = this.config.defaultValue;
    }

    if (!this.config.editable) {
      inputNode.readOnly = true;
    }

    //Track inputNode for retrieval later
    this._inputNode = inputNode;

    // This feature is currently blocked. There's a bug in Adobe API that
    // has been reported. Once this bug is fixed, it will be enabled in
    // the next version.

    // // If group is a recipient group
    // if (this.recipient_group_data['maxListCount'] > 1) {
    //     this.createAdditionalRecipientInput(inputNode.id);
    //     this.removeParticipentButton(this.divNode);
    // }

    return;
  }

  setupValidation(validator: Validator): void {
    const validationTracker = validator.createTracker(this._inputNode, this.runValidation);

    this._inputNode.addEventListener("change", (event: Event) => {
      this.runValidation(validationTracker, event, false);
    });
  }

  runValidation = (
    validationTracker: ValidationTracker,
    _event: Event,
    _isRevalidate: boolean,
  ): void => {
    let error = false;
    let message = null;
    const email = this._inputNode.value;

    if (this.required && email == "") {
      error = true;
      message = `The recipient "${this.config.label}" is required.`;
    } else if (this.config.editable && email != "" && !Utils.isValidEmail(email)) {
      error = true;
      message = `The email "${email}" for recipient "${this.config.label}" is not a valid email address.`;
    }

    if (error) {
      this._inputNode.classList.add("is-invalid");
    } else {
      DOMUtils.removeClass(this._inputNode, "is-invalid");
    }

    validationTracker.update(error, message);
  };

  getValue(): ParticipantSetData | null {
    if (this.config.editable) {
      return {
        name: this.config.name,
        recipients: [
          {
            email: this._inputNode.value,
          },
        ],
      };
    }
    return null;
  }

  // createAdditionalRecipientInput(recipient_id) {
  //   /***
  //    * This function add additions recipeints input
  //    */

  //   const add_div = document.createElement("div");
  //   add_div.id = "add_section_" + this.group_id;
  //   add_div.className = "add_section";
  //   this._divNode.appendChild(add_div);

  //   // Create the add new recipient button
  //   const add_marker_button = document.createElement("button");
  //   add_marker_button.type = "button";
  //   add_marker_button.id = "add_button";

  //   // Add onclick function to allow us to create new recipient inputs
  //   add_marker_button.onclick = () => {
  //     const new_recipient_id = recipient_id + "_" + this.number_of_members;
  //     this.number_of_members++;
  //     this.appendNewParticipentInput(new_recipient_id);
  //   };

  //   add_div.append(add_marker_button);

  //   // Add the plus icon to the button
  //   const add_recipient_marker = document.createElement("i");
  //   add_recipient_marker.className = "fa fa-plus";

  //   add_marker_button.appendChild(add_recipient_marker);
  // }

  // appendNewParticipentInput(participent_id) {
  //   /***
  //    * This functiuon appends a new recipient input
  //    */

  //   // Create new input field
  //   const participent_input = document.createElement("input");
  //   participent_input.type = "text";
  //   participent_input.className = "form-control";
  //   participent_input.placeholder = "Enter Recipient's Email";
  //   participent_input.id = participent_id;
  //   participent_input.name = participent_id;

  //   // Append to the div before buttons
  //   const target = document.getElementById("add_section_" + this.group_id);
  //   this._divNode.insertBefore(participent_input, target);
  // }

  // removeParticipentButton() {
  //   /***
  //    * This function removes a recipient
  //    */

  //   const remove_button: HTMLButtonElement = document.createElement("button");
  //   remove_button.type = "button";
  //   remove_button.id = "remove_button";
  //   remove_button.onclick = () => {
  //     if (this.number_of_members > 0) {
  //       // remove input field
  //       this._divNode.removeChild(this._divNode.querySelectorAll("input")[this.number_of_members]);
  //       this.number_of_members--;
  //     }
  //   };
  //   document.getElementById("add_section_" + this.group_id).appendChild(remove_button);

  //   const remove_button_marker = document.createElement("i");
  //   remove_button_marker.className = "fa fa-minus";
  //   remove_button.appendChild(remove_button_marker);
  // }
}

import DOMUtils from "../util/DOMUtils";
import * as DateUtils from "../util/Date";
import expirationTemplate from "../../hbsTemplates/Expiration.hbs";
import { isNaN } from "lodash-es";
import { ExpirationInfo } from "../models";
import Validator, { ValidationTracker } from "../Validator";

export class Expiration {
  config: ExpirationInfo;
  today: Date;
  minDays: number;
  maxDays: number;
  private _expireCheckbox: HTMLInputElement;
  private _detailsDiv: HTMLDivElement;
  private _expirationInput: HTMLInputElement;
  private _expirationAfterText: HTMLSpanElement;
  private _expirationError: HTMLSpanElement;

  constructor(config: ExpirationInfo) {
    this.config = config;

    this.today = new Date();
    this.minDays = 1;
    this.maxDays = config.maxDays;
  }

  addToDOM(parentNode: HTMLElement): void {
    const data = {
      checked: this.config.defaultValue ? "checked" : "",
      readonly: !this.config.editable,
      minDays: this.minDays,
      maxDays: this.maxDays,
      value: this.config.defaultValue,
      inputLabelEnd: "to complete this agreement.",
      inputLabel: "days",
    };

    // Create the div
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = expirationTemplate(data);
    const div = tempDiv.firstChild as HTMLDivElement;
    parentNode.appendChild(div);

    //create hooks
    this._expireCheckbox = div.querySelector<HTMLInputElement>("#expiration-checkbox");
    this._detailsDiv = div.querySelector<HTMLDivElement>("#expiration-details");
    this._expirationInput = div.querySelector<HTMLInputElement>("#expiration-in-days-val");
    this._expirationAfterText = div.querySelector<HTMLSpanElement>(".expiration-after-text");
    this._expirationError = div.querySelector<HTMLSpanElement>(".expiration-error-text");

    this.updateExpirationAfterText();

    // Add event handlers
    this._expireCheckbox.onclick = () => {
      if (this._expireCheckbox.checked === true) {
        this._detailsDiv.hidden = false;
        this._expirationInput.focus();
      } else {
        this._detailsDiv.hidden = true;
      }
    };
  }

  setupValidation(validator: Validator): void {
    const validationTracker = validator.createTracker(this._expirationInput, this.runValidation);

    this._expireCheckbox.addEventListener("click", (event: Event) => {
      //only run when unchecking
      if (this._expireCheckbox.checked !== true) {
        this.runValidation(validationTracker, event, false);
      }
    });

    this._expirationInput.addEventListener("change", (event: Event) => {
      this.runValidation(validationTracker, event, false);
    });
  }

  runValidation = (
    validationTracker: ValidationTracker,
    _event: Event,
    _isRevalidate: boolean,
  ): void => {
    let error = false;
    let message = null;
    const expirationDays = this._expirationInput.value;
    const daysInt = parseInt(expirationDays);

    if (this._expireCheckbox.checked === true) {
      if (this.config.editable && expirationDays == "") {
        error = true;
        message = "Please enter the number of days after which this document expires.";
      } else {
        const minDays = this.minDays;
        const maxDays = this.maxDays;

        if (isNaN(daysInt)) {
          error = true;
          message = "Agreement expiration may only contain numbers.";
        } else if (daysInt < minDays) {
          error = true;
          message = "There is a minimum of 1 day to set agreement expiration.";
        } else if (daysInt > maxDays) {
          error = true;
          message = `There is a maximum of ${maxDays} days before agreement expiration.`;
        }
      }
    }

    if (error) {
      this._expirationError.innerText = message;
      this._expirationError.hidden = false;
      this._expirationAfterText.innerText = "";
      this._expirationAfterText.hidden = true;

      this._expirationInput.classList.add("is-invalid");
    } else {
      this._expirationError.innerText = "";
      this._expirationError.hidden = true;
      this.updateExpirationAfterText();

      DOMUtils.removeClass(this._expirationInput, "is-invalid");
    }

    validationTracker.update(error, message);
  };

  getValue(): number | null {
    if (this._expireCheckbox.checked === true) {
      return parseInt(this._expirationInput.value);
    }
    return null;
  }

  updateExpirationAfterText() {
    const expirationDays = this._expirationInput.value;
    const daysInt = parseInt(expirationDays);

    if (isNaN(daysInt)) {
      this._expirationAfterText.hidden = true;
      return;
    }

    const expirationDate = DateUtils.addDays(this.today, daysInt);
    this._expirationAfterText.innerText = `Agreement expires after ${expirationDate.toLocaleDateString(
      "en-us",
      { year: "numeric", month: "short", day: "numeric" },
    )}.`;
    this._expirationAfterText.hidden = false;
  }
}

import reminderSectionTemplate from "../../hbsTemplates/ReminderSection.hbs";
import { PowerFormParamInfo, ReminderFrequency } from "../models";

export class Reminder {
  config: PowerFormParamInfo;
  private _reminderCheckbox: HTMLInputElement;
  private _detailsDiv: HTMLDivElement;
  private _reminderSelect: HTMLSelectElement;

  constructor(config: PowerFormParamInfo) {
    this.config = config;
  }

  addToDOM(parentNode: HTMLElement): void {
    const data = {
      checked: false,
      disabled: !this.config.editable,
      reminderFrequencyOptions: [
        {
          value: ReminderFrequency.DAILY_UNTIL_SIGNED.toString(),
          descr: "Every day",
          selected: false,
        },
        {
          value: ReminderFrequency.WEEKLY_UNTIL_SIGNED.toString(),
          descr: "Every week",
          selected: false,
        },
        {
          value: ReminderFrequency.WEEKDAILY_UNTIL_SIGNED.toString(),
          descr: "Every business day",
          selected: false,
        },
        {
          value: ReminderFrequency.EVERY_OTHER_DAY_UNTIL_SIGNED.toString(),
          descr: "Every other day",
          selected: false,
        },
        {
          value: ReminderFrequency.EVERY_THIRD_DAY_UNTIL_SIGNED.toString(),
          descr: "Every third day",
          selected: false,
        },
        {
          value: ReminderFrequency.EVERY_FIFTH_DAY_UNTIL_SIGNED.toString(),
          descr: "Every fifth day",
          selected: false,
        },
      ],
    };

    if (this.config.defaultValue && this.config.defaultValue != "") {
      for (let i = 0; i < data.reminderFrequencyOptions.length; i++) {
        if (data.reminderFrequencyOptions[i].value == this.config.defaultValue) {
          data.reminderFrequencyOptions[i].selected = true;
          data.checked = true;
        }
      }
    }

    // Create the div
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = reminderSectionTemplate(data);
    const div = tempDiv.firstChild as HTMLDivElement;
    parentNode.appendChild(div);

    //create hooks
    this._reminderCheckbox = div.querySelector<HTMLInputElement>("#reminder-option");
    this._detailsDiv = div.querySelector<HTMLDivElement>("#reminder-details");
    this._reminderSelect = div.querySelector<HTMLSelectElement>("#reminder-frequency");

    // Add event handlers
    this._reminderCheckbox.onclick = () => {
      if (this._reminderCheckbox.checked === true) {
        this._detailsDiv.hidden = false;
        this._reminderSelect.focus();
      } else {
        this._detailsDiv.hidden = true;
      }
    };
  }

  getValue(): ReminderFrequency | null {
    if (this._reminderCheckbox.checked === true) {
      return this._reminderSelect.value as ReminderFrequency;
    }
    return null;
  }
}

import appErrorTemplate from "../hbsTemplates/AppError.hbs";
import { WorkflowForm } from "./WorkflowForm";
import { WorkflowService } from "./WorkflowService";
import { ApiClient } from "./util/APIClient";
import {
  APIException,
  HandledException,
  InternalServerError,
  errorTemplateConfig,
} from "./util/Exceptions";

export class App {
  private appDiv: HTMLElement;
  private config: ClientConfig;
  private apiClient: ApiClient;

  constructor(appDiv: HTMLElement, config: ClientConfig) {
    this.appDiv = appDiv;
    this.config = config;

    this.apiClient = new ApiClient(config.apiBaseURL);
  }

  async loadWorkflow(workflowId: string): Promise<void> {
    try {
      const wfService = new WorkflowService(workflowId, this.config, this.apiClient);
      const workflowConfig = await wfService.getWorflowConfig();

      // Render the form
      document.title = `${this.config.appTitle} - ${workflowConfig.displayName}`;
      new WorkflowForm(workflowConfig, wfService, this.appDiv);
    } catch (e) {
      if (e instanceof HandledException) {
        this.showError(e.getTemplateVars());
      } else if (e instanceof APIException || e instanceof InternalServerError) {
        console.error("Unhandled Error", e);
        this.showError({
          errorBody: "Sorry an unknown API error occured.",
          requestId: e.requestId,
        });
      } else {
        console.error("Unhandled Error", e);
        this.showError({ errorBody: "Sorry an unknown error occured." });
      }
    }
  }

  /**
   * Show an error instead of any other UI
   * @param config errorTemplateConfig
   */
  showError(config: errorTemplateConfig): void {
    this.appDiv.innerHTML = appErrorTemplate(config);
  }
}

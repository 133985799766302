type ValidationFunction = (
  validationTracker: ValidationTracker,
  event: Event,
  isRevalidate: boolean,
) => void;

export default class Validator {
  private _trackers: {
    [index: number]: ValidationTracker;
  };
  private _observers: {
    [index: string]: ValidationObserver;
  };
  private nextTrackerId: number;

  constructor() {
    this._observers = {};
    this._trackers = {};
    this.nextTrackerId = 1;
  }

  createTracker(elm: HTMLElement, validationFn: ValidationFunction) {
    const id = this.nextTrackerId++;
    const tracker = new ValidationTracker(id, elm, validationFn);
    for (const observerId in this._observers) {
      tracker.addObserver(observerId, this._observers[observerId]);
    }

    this._trackers[id] = tracker;

    return tracker;
  }

  createObserver(id: string, callback: ObserverCallback): ValidationObserver {
    const observer = new ValidationObserver(id, callback);

    this._observers[id] = observer;

    for (const trackerId in this._trackers) {
      this._trackers[trackerId].addObserver(id, observer);
    }

    return observer;
  }

  revalidateAll(): void {
    for (const trackerId in this._trackers) {
      this._trackers[trackerId].revalidate();
    }
  }
}

export interface ValidationErrors {
  [index: number]: {
    message: string | null;
    elm: HTMLElement;
  };
}

type ObserverCallback = (errors: ValidationErrors) => void;

export class ValidationObserver {
  id: string;
  currentErrors: ValidationErrors;
  private _callback: ObserverCallback;

  constructor(id: string, callback: ObserverCallback) {
    this.id = id;
    this._callback = callback;

    this.currentErrors = {};
  }

  observerFn = (id: number, errorFlag: boolean, message: string | null, elm: HTMLElement): void => {
    //console.log({id, errorFlag, message, elm})
    if (errorFlag) {
      this.currentErrors[id] = { message, elm };
    } else {
      delete this.currentErrors[id];
    }

    this._callback(this.currentErrors);
  };
}

export class ValidationTracker {
  id: number;
  elm: HTMLElement;
  validationFn: ValidationFunction;
  private _error: boolean;
  private _message: string | null;
  private _observers: {
    [index: string]: ValidationObserver;
  };

  constructor(id: number, elm: HTMLElement, validationFn: ValidationFunction) {
    this.id = id;
    this.elm = elm;
    this.validationFn = validationFn;
    this._error = false;
    this._message = null;
    this._observers = {};
  }

  get error(): boolean {
    return this._error;
  }

  set error(newError: boolean) {
    if (newError !== this._error) {
      this._error = newError;
      this._notifyObservers();
    }
  }

  get message(): string | null {
    return this._message;
  }

  set message(newMessage: string | null) {
    if (newMessage !== this._message) {
      this._message = newMessage;
      this._notifyObservers();
    }
  }

  _notifyObservers(): void {
    for (const id in this._observers) {
      this._observers[id].observerFn(this.id, this._error, this._message, this.elm);
      /*error: this._error,
        message: this._message,
        id: this.id,
        elm: this.elm
      });*/
    }
  }

  update(newError: boolean, newMessage: string | null) {
    //console.log({newError,oldError: this._error,newMessage,oldMessage: this._message});
    if (newError !== this._error || newMessage !== this._message) {
      this._error = newError;
      this._message = newMessage;

      this._notifyObservers();
    }
  }

  addObserver(id: string, observer: ValidationObserver): void {
    this._observers[id] = observer;
  }

  removeObserver(id: string): void {
    delete this._observers[id];
  }

  revalidate(): void {
    this.validationFn(this, null, true);
  }
}

var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"workflow_form\">\n  <div class=\"workflow_header\">\n    <h1>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"displayName") || (depth0 != null ? lookupProperty(depth0,"displayName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"displayName","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":23}}}) : helper)))
    + "</h1>\n  </div>\n  <div class=\"workflow_content\">\n    <div id=\"form-errors\" class=\"collapse fade show\"></div>\n    <form enctype=\"multipart/form-data\">\n      <div id=\"instruction_section\"></div>\n      <div id=\"recipient_section\"></div>\n      <div id=\"cc_section\"></div>\n      <div class=\"row\">\n        <div class=\"col-lg-8\">\n          <div id=\"agreement_section\"></div>\n          <div id=\"message_section\"></div>\n          <div id=\"upload_section\"></div>\n          <div id=\"merge_section\"></div>\n        </div>\n        <div class=\"col-lg-4\">\n          <div class=\"compose-options\" role=\"group\" aria-labelledby=\"compose-options-heading\">\n            <h2 id=\"compose-options-heading\">Options</h3>\n          </div>\n        </div>\n      </div>\n      <button class=\"btn btn-primary btn-custom\" type=\"button\" id=\"form_submit_button\">Submit</button>\n    </form>\n  </div>\n</div>\n";
},"useData":true});
export function addDays(date: Date, days: number): Date {
  // Create new date object and add the days delta to it
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function format(date: Date, formatStr: string): string {
  // Create the day, month, and year variables
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // convert to strings & pad
  const dayStr = day.toString().padStart(2, "0");
  const monthStr = month.toString().padStart(2, "0");
  const yearStr = year.toString();

  return formatStr.replace("MM", monthStr).replace("DD", dayStr).replace("YYYY", yearStr);
}

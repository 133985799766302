import DOMUtils from "../util/DOMUtils";
import messageSectionTemplate from "../../hbsTemplates/MessageSection.hbs";
import { PowerFormParamInfo } from "../models";
import Validator, { ValidationTracker } from "../Validator";

export class MessageSection {
  config: PowerFormParamInfo;
  private _inputNode: HTMLTextAreaElement;

  constructor(config: PowerFormParamInfo) {
    this.config = config;
  }

  addToDOM(parentNode: HTMLElement): void {
    const data = {
      defaultValue: this.config.defaultValue.trim(),
      readonly: !this.config.editable,
    };

    // Create the div
    parentNode.innerHTML = messageSectionTemplate(data);

    //create hooks
    this._inputNode = parentNode.querySelector<HTMLTextAreaElement>("textarea");
  }

  setupValidation(validator: Validator): void {
    const validationTracker = validator.createTracker(this._inputNode, this.runValidation);

    this._inputNode.addEventListener("change", (event) => {
      this.runValidation(validationTracker, event, false);
    });
  }

  runValidation = (
    validationTracker: ValidationTracker,
    _event: Event,
    _isRevalidate: boolean,
  ): void => {
    let error = false;
    let message = null;

    if (this._inputNode.value == "") {
      error = true;
      message = "Please provide a valid value for agreement message.";
    }

    if (error) {
      this._inputNode.classList.add("is-invalid");
    } else {
      DOMUtils.removeClass(this._inputNode, "is-invalid");
    }

    validationTracker.update(error, message);
  };

  getValue(): string | null {
    if (this.config.editable) {
      return this._inputNode.value;
    }
    return null;
  }
}

import { ParseOptions, TokensToRegexpOptions, pathToRegexp, Key } from "path-to-regexp";

interface RouteParams {
  [index: number | string]: string;
}

export default class ParsePath {
  options: TokensToRegexpOptions & ParseOptions;

  constructor(options?: TokensToRegexpOptions & ParseOptions) {
    this.options = options || {};
  }

  compile(route: string) {
    const keys: Key[] = [];
    const regexp = pathToRegexp(route, keys, this.options);

    return function (path: string): false | RouteParams {
      const match = regexp.exec(path);
      if (!match) {
        return false;
      }

      const params: RouteParams = {};

      //var key, param;
      for (let i = 0; i < keys.length; i++) {
        const param = match[i + 1];
        if (!param) {
          continue;
        }

        const key = keys[i];
        params[key.name] = decode_param(param);
      }

      return params;
    };
  }

  test(route: string, path: string) {
    const params = this.compile(route)(path);

    if (params === false) {
      return false;
    } else {
      return params;
    }
  }
}

function decode_param(val: string) {
  if (typeof val !== "string" || val.length === 0) {
    return val;
  }

  try {
    return decodeURIComponent(val);
  } catch (err) {
    throw new Error(`Cannot decodeURIComponent: ${val}`);
  }
}

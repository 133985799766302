import { ApiErrorDetails } from "../models";

export interface errorTemplateConfig {
  heading?: string | null;
  errorBody: string;
  requestId?: string | null;
  allowDismiss?: boolean | null;
}

export class APIException extends Error {
  __proto__ = Error;
  apiData: ApiErrorDetails;
  requestId?: string;

  constructor(apiData: ApiErrorDetails, requestId: string = null, message?: string) {
    super(message);
    Object.setPrototypeOf(this, APIException.prototype);
    this.name = this.constructor.name;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    Error.captureStackTrace?.(this, this.constructor);

    // Custom debugging information
    this.apiData = apiData;
    this.requestId = requestId;
  }
}

export class HandledException extends Error {
  __proto__ = Error;
  heading: string;
  requestId?: string;
  allowDismiss: boolean;

  constructor(
    heading: string,
    requestId: string = null,
    message?: string,
    allowDismiss: boolean = false,
  ) {
    super(message);
    Object.setPrototypeOf(this, HandledException.prototype);
    this.name = this.constructor.name;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    Error.captureStackTrace?.(this, this.constructor);

    // Custom debugging information
    this.heading = heading;
    this.requestId = requestId;
    this.allowDismiss = allowDismiss;
  }

  getTemplateVars(): errorTemplateConfig {
    return {
      heading: this.heading,
      errorBody: this.message,
      requestId: this.requestId,
      allowDismiss: this.allowDismiss,
    };
  }
}

export class InternalServerError extends Error {
  __proto__ = Error;
  apiData: unknown;
  requestId?: string;

  constructor(apiData: unknown, requestId: string = null, message?: string) {
    super(message);
    Object.setPrototypeOf(this, InternalServerError.prototype);
    this.name = this.constructor.name;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    Error.captureStackTrace?.(this, this.constructor);

    // Custom debugging information
    this.apiData = apiData;
    this.requestId = requestId;
  }
}

export class DocUploadError extends Error {
  __proto__ = Error;

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, DocUploadError.prototype);
    this.name = this.constructor.name;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    Error.captureStackTrace?.(this, this.constructor);
  }
}

import {
  AgreementName,
  CarbonCopyGroup,
  Expiration,
  ExpirationDateTimeBased,
  FileSelect,
  FileUpload,
  FormErrors,
  MergeField,
  MessageSection,
  PasswordOption,
  RecipientGroup,
  Reminder,
} from "./form_components";
import Modal from "./shared_components/Modal";
import DOMUtils from "./util/DOMUtils";
import Validator from "./Validator";
import workflowFormTemplate from "../hbsTemplates/WorkflowForm.hbs";
import submitCompleteTemplate from "../hbsTemplates/SubmitComplete.hbs";
import * as models from "./models";
import { WorkflowService } from "./WorkflowService";
import { APIException, HandledException, InternalServerError } from "./util/Exceptions";

export class WorkflowForm {
  config: models.PowerFormInfo;
  wfService: WorkflowService;
  validator: Validator;
  private _appDiv: HTMLElement;
  private _submitButton: HTMLButtonElement;
  private _formErrors: FormErrors;
  private formHooks: {
    agreementName?: AgreementName;
    recipientGroups: RecipientGroup[];
    carbonCopyGroups: CarbonCopyGroup[];
    message?: MessageSection;
    files: (FileSelect | FileUpload)[];
    mergeFields: MergeField[];
    passwordOption?: PasswordOption;
    expiration?: Expiration | ExpirationDateTimeBased;
    reminder?: Reminder;
  };

  constructor(config: models.PowerFormInfo, wfService: WorkflowService, appDiv: HTMLElement) {
    this.config = config;
    this.wfService = wfService;
    this._appDiv = appDiv;

    this.validator = new Validator();

    // Create formHooks with empty arrays
    this.formHooks = {
      recipientGroups: [],
      carbonCopyGroups: [],
      files: [],
      mergeFields: [],
    };

    //set template
    appDiv.innerHTML = workflowFormTemplate({ displayName: this.config.displayName });

    //build sections
    this.createInstructionSection(appDiv.querySelector("#instruction_section"));
    this.createRecipientSection(appDiv.querySelector("#recipient_section"));
    this.createCCSection(appDiv.querySelector("#cc_section"));
    this.createAgreementNameSection(appDiv.querySelector("#agreement_section"));
    this.createMessageSection(appDiv.querySelector("#message_section"));
    this.createUploadSection(appDiv.querySelector("#upload_section"));
    this.createMergeSection(appDiv.querySelector("#merge_section"));
    this.createOptionsSection(appDiv.querySelector(".compose-options"));

    // deal with submit
    this._submitButton = appDiv.querySelector<HTMLButtonElement>("button#form_submit_button");

    // Add Errors section
    this._formErrors = new FormErrors(appDiv.querySelector("#form-errors"), this.validator);
    this._formErrors.connectSubmitButton(this._submitButton);

    // Add onClick event to submit button
    this._submitButton.onclick = this._handleSubmit;
  }

  _handleSubmit = async () => {
    this.validator.revalidateAll();
    this._formErrors.showErrorDiv();

    if (this._formErrors.hasErrors()) {
      this._formErrors.scrollToError();
      console.log("Validation errors found, not submitting form");
      return false;
    }

    //disable submit
    this._submitButton.disabled = true;

    const modal = new Modal(this._appDiv);
    modal.showLoader("Submitting...");

    try {
      //Submit the agreement
      const agreementData = this.getAgreementData();
      console.log("agreementData", agreementData);
      const result = await this.wfService.submitAgreement(agreementData);
      console.log("Submit result", result);

      this._appDiv.querySelector(".workflow_content").innerHTML = submitCompleteTemplate({
        body: "The agreement has been submitted successfully. The first recipient should receive an email shortly with a link to the agreement.",
      });

      modal.remove();
    } catch (e) {
      if (e instanceof HandledException) {
        modal.showError(e.getTemplateVars());
      } else if (e instanceof APIException || e instanceof InternalServerError) {
        console.error("Unhandled Error", e);
        modal.showError({
          errorBody: "Sorry an unknown API error occured.",
          requestId: e.requestId,
        });
      } else {
        console.error("Unhandled Error", e);
        modal.showError({ errorBody: "Sorry an unknown error occured." });
      }

      //enable submit
      this._submitButton.disabled = false;
    }
  };

  getAgreementData(): models.PowerFormAgreementCreate {
    const agreementData: models.PowerFormAgreementCreate = {};

    if (this.formHooks.agreementName !== undefined) {
      agreementData.agreementName = this.formHooks.agreementName.getValue();
    }

    if (this.formHooks.message !== undefined) {
      agreementData.message = this.formHooks.message.getValue();
    }

    const recipients = this.formHooks.recipientGroups.reduce<models.ParticipantSetData[]>(
      (values: models.ParticipantSetData[], elm: RecipientGroup) => {
        const val = elm.getValue();
        if (val !== null) {
          values.push(val);
        }
        return values;
      },
      [],
    );
    if (recipients.length) {
      agreementData.recipients = recipients;
    }

    const carbonCopy = this.formHooks.carbonCopyGroups.reduce<models.CarbonCopyData[]>(
      (values: models.CarbonCopyData[], elm: CarbonCopyGroup) => {
        const val = elm.getValue();
        if (val !== null) {
          values.push(val);
        }
        return values;
      },
      [],
    );
    if (carbonCopy.length) {
      agreementData.carbonCopy = carbonCopy;
    }

    const files = this.formHooks.files.reduce<models.FileData[]>(
      (values: models.FileData[], elm: FileSelect | FileUpload) => {
        const val = elm.getValue();
        if (val !== null) {
          values.push(val);
        }
        return values;
      },
      [],
    );
    if (files.length) {
      agreementData.files = files;
    }

    const mergeFields = this.formHooks.mergeFields.reduce<models.MergeFieldData[]>(
      (values: models.MergeFieldData[], elm: MergeField) => {
        const val = elm.getValue();
        if (val !== null) {
          values.push(val);
        }
        return values;
      },
      [],
    );
    if (mergeFields.length) {
      agreementData.mergeFields = mergeFields;
    }

    if (this.formHooks.reminder !== undefined) {
      agreementData.reminder = this.formHooks.reminder.getValue();
    }

    if (this.formHooks.expiration !== undefined) {
      agreementData.expiration = this.formHooks.expiration.getValue();
    }

    if (this.formHooks.passwordOption !== undefined) {
      agreementData.password = this.formHooks.passwordOption.getValue();
    }

    return agreementData;
  }

  private createInstructionSection(sectionNode: HTMLElement): void {
    sectionNode.innerHTML = this.config.instructions;
  }

  private createRecipientSection(sectionNode: HTMLElement): void {
    //reset current node
    this.resetDOMNode(sectionNode, true);

    const config = this.config.recipients;
    if (config && config.length > 0) {
      const fieldsetNode = document.createElement("fieldset");
      sectionNode.appendChild(fieldsetNode);

      const headerNode = document.createElement("legend");
      headerNode.innerHTML = "Recipients";
      fieldsetNode.appendChild(headerNode);

      // Get Recipient Information
      for (let counter = 0; counter < config.length; counter++) {
        const recipientGrp = new RecipientGroup(counter, config[counter]);
        recipientGrp.addToDOM(fieldsetNode);
        recipientGrp.setupValidation(this.validator);
        this.formHooks.recipientGroups.push(recipientGrp);
      }

      DOMUtils.removeClass(sectionNode, "hidden");
    }
  }

  private createCCSection(sectionNode: HTMLElement): void {
    //reset current node
    this.resetDOMNode(sectionNode, true);

    const config = this.config.carbonCopies;
    if (config && config.length > 0) {
      for (let counter = 0; counter < config.length; counter++) {
        const ccGrp = new CarbonCopyGroup(counter, config[counter]);
        ccGrp.addToDOM(sectionNode);
        ccGrp.setupValidation(this.validator);
        this.formHooks.carbonCopyGroups.push(ccGrp);
      }

      DOMUtils.removeClass(sectionNode, "hidden");
    }
  }

  private createAgreementNameSection(sectionNode: HTMLElement): void {
    //reset current node
    this.resetDOMNode(sectionNode, true);

    const config = this.config.agreementName;
    if (config) {
      const agreementName = new AgreementName(config);
      agreementName.addToDOM(sectionNode);
      agreementName.setupValidation(this.validator);
      this.formHooks.agreementName = agreementName;

      DOMUtils.removeClass(sectionNode, "hidden");
    }
  }

  private createMessageSection(sectionNode: HTMLElement): void {
    //reset current node
    this.resetDOMNode(sectionNode, true);

    const config = this.config.message;
    if (config) {
      const messageSection = new MessageSection(config);
      messageSection.addToDOM(sectionNode);
      messageSection.setupValidation(this.validator);
      this.formHooks.message = messageSection;

      DOMUtils.removeClass(sectionNode, "hidden");
    }
  }

  private createUploadSection(sectionNode: HTMLElement): void {
    //reset current node
    this.resetDOMNode(sectionNode, true);

    const config = this.config.files;
    if (config && config.length > 0) {
      const fieldsetNode = document.createElement("fieldset");
      sectionNode.appendChild(fieldsetNode);

      const headerNode = document.createElement("legend");
      headerNode.innerHTML = "Files";
      fieldsetNode.appendChild(headerNode);

      // Get FileInfo information
      for (let counter = 0; counter < config.length; counter++) {
        if (config[counter].workflowLibraryDocumentSelectorList) {
          const file = new FileSelect(config[counter]);
          file.addToDOM(fieldsetNode);
          file.setupValidation(this.validator);
          this.formHooks.files.push(file);
        } else {
          const file = new FileUpload(config[counter], this.wfService);
          file.addToDOM(fieldsetNode);
          file.setupValidation(this.validator);
          this.formHooks.files.push(file);
        }
      }

      DOMUtils.removeClass(sectionNode, "hidden");
    }
  }

  private createMergeSection(sectionNode: HTMLElement): void {
    //reset current node
    this.resetDOMNode(sectionNode, true);

    const config = this.config.mergeFields;
    if (config && config.length > 0) {
      const fieldsetNode = document.createElement("fieldset");
      sectionNode.appendChild(fieldsetNode);

      const headerNode = document.createElement("legend");
      headerNode.innerHTML = "Fields";
      fieldsetNode.appendChild(headerNode);

      // Get merged field information
      for (let counter = 0; counter < config.length; counter++) {
        const mergeField = new MergeField(config[counter]);
        mergeField.addToDOM(fieldsetNode);
        mergeField.setupValidation(this.validator);
        this.formHooks.mergeFields.push(mergeField);
      }

      DOMUtils.removeClass(sectionNode, "hidden");
    }
  }

  private createOptionsSection(sectionNode: HTMLElement): void {
    const showPassword = this.createPasswordSubsection(sectionNode);
    const showExpiration = this.createExpirationSubsection(sectionNode);
    const showReminder = this.createReminderSubsection(sectionNode);

    if (!showPassword && !showExpiration && !showReminder) {
      sectionNode.classList.add("hidden");
    }
  }

  private createPasswordSubsection(sectionNode: HTMLElement): boolean {
    const config = this.config.password;

    // Get Password information
    if (config.visible) {
      const passwordOption = new PasswordOption(config);
      passwordOption.addToDOM(sectionNode);
      passwordOption.setupValidation(this.validator);
      this.formHooks.passwordOption = passwordOption;
      return true;
    }

    return false;
  }

  private createExpirationSubsection(sectionNode: HTMLElement): boolean {
    const config = this.config.expiration;
    if (config && config.visible) {
      const expiration = window.ClientConfig.expirationAsDate
        ? new ExpirationDateTimeBased(config)
        : new Expiration(config);
      expiration.addToDOM(sectionNode);
      expiration.setupValidation(this.validator);
      this.formHooks.expiration = expiration;
      return true;
    }

    return false;
  }

  private createReminderSubsection(sectionNode: HTMLElement): boolean {
    const config = this.config.reminder;
    if (config && config.visible) {
      const reminder = new Reminder(config);
      reminder.addToDOM(sectionNode);
      //No validation
      this.formHooks.reminder = reminder;
      return true;
    }

    return false;
  }

  private resetDOMNode(node: HTMLElement, hide: boolean) {
    if (hide) {
      node.classList.add("hidden");
    } else {
      DOMUtils.removeClass(node, "hidden");
    }

    //Remove children
    while (node.firstElementChild) {
      node.firstElementChild.remove();
    }
  }
}

var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " readonly ";
},"3":function(container,depth0,helpers,partials,data) {
    return "hidden ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"expiration-section compose-option-section\">\n  <div role=\"group\" aria-labelledby=\"expiration-label\">\n    <div class=\"form-check compose-option-group\" role=\"presentation\">\n      <input type=\"checkbox\" id=\"expiration-checkbox\"\n             class=\"form-check-input expiration\"\n             "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readonly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":13},"end":{"line":6,"column":46}}})) != null ? stack1 : "")
    + "\n             "
    + alias4(((helper = (helper = lookupProperty(helpers,"checked") || (depth0 != null ? lookupProperty(depth0,"checked") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checked","hash":{},"data":data,"loc":{"start":{"line":7,"column":13},"end":{"line":7,"column":24}}}) : helper)))
    + ">\n      <label class=\"form-check-label\" id=\"expiration-label\" for=\"expiration-checkbox\">Completion Deadline</label>\n    </div>\n    <div id=\"expiration-details\" class=\"form-group expiration-in-time\" role=\"presentation\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":91},"end":{"line":10,"column":128}}})) != null ? stack1 : "")
    + ">\n      <input id=\"expiration-time\" type=\"date\" class=\"form-control\"\n             value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":29}}}) : helper)))
    + "\"\n             min=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"minDate") || (depth0 != null ? lookupProperty(depth0,"minDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minDate","hash":{},"data":data,"loc":{"start":{"line":13,"column":18},"end":{"line":13,"column":29}}}) : helper)))
    + "\"\n             max=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxDate") || (depth0 != null ? lookupProperty(depth0,"maxDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxDate","hash":{},"data":data,"loc":{"start":{"line":14,"column":18},"end":{"line":14,"column":29}}}) : helper)))
    + "\"\n             "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readonly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":13},"end":{"line":15,"column":46}}})) != null ? stack1 : "")
    + "\n             required />\n    </div>\n  </div>\n</div>\n";
},"useData":true});
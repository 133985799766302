import DOMUtils from "../util/DOMUtils";
import mergeFieldTemplate from "../../hbsTemplates/MergeField.hbs";
import { unescape } from "lodash-es";
import { MergeFieldData, MergeFieldInfo } from "../models";
import Validator, { ValidationTracker } from "../Validator";

export class MergeField {
  config: MergeFieldInfo;
  readonly: boolean;
  private _inputNode: HTMLInputElement;

  constructor(config: MergeFieldInfo) {
    this.config = config;
    this.readonly = !config.editable;
  }

  addToDOM(parentNode: HTMLElement): void {
    const data = {
      inputId: "merge_input_" + this.config.fieldName,
      label: unescape(this.config.displayName),
      defaultValue: this.config.defaultValue,
      required: this.config.required,
      readonly: this.readonly,
    };

    // Create the div
    const tempDiv: HTMLDivElement = document.createElement("div");
    tempDiv.innerHTML = mergeFieldTemplate(data);
    const div = tempDiv.firstChild as HTMLDivElement;
    parentNode.appendChild(div);

    //create hooks
    this._inputNode = div.querySelector<HTMLInputElement>("input");
  }

  setupValidation(validator: Validator): void {
    const validationTracker = validator.createTracker(this._inputNode, this.runValidation);

    this._inputNode.addEventListener("change", (event) => {
      this.runValidation(validationTracker, event, false);
    });
  }

  runValidation = (
    validationTracker: ValidationTracker,
    _event: Event,
    _isRevalidate: boolean,
  ): void => {
    let error = false;
    let message = null;
    const value = this._inputNode.value;

    if (this.config.required && value == "") {
      error = true;
      message = `The field "${this.config.displayName}" is required.`;
    }

    if (error) {
      this._inputNode.classList.add("is-invalid");
    } else {
      DOMUtils.removeClass(this._inputNode, "is-invalid");
    }

    validationTracker.update(error, message);
  };

  getValue(): MergeFieldData | null {
    if (!this.readonly) {
      return {
        fieldName: this.config.fieldName,
        defaultValue: this._inputNode.value,
      };
    }
    return null;
  }
}
